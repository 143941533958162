















































import Vue from 'vue';
import { Thing } from '../../models/Thing';
import { ThingsService } from '../../services/things/ThingsService';
import SiteAlerts from './SiteAlerts.vue';
import SiteSignalStrength from './SiteSignalStrength.vue';
import LoadingOverlay from '../common/LoadingOverlay.vue';

export default Vue.extend({
  name: 'SiteMarker',

  components: {
    SiteAlerts,
    SiteSignalStrength,
    LoadingOverlay,
  },

  props: {
    thingId: {
      type: String,
      required: true,
    },
  },

  watch: {
    async thingId(): Promise<void> {
      this.isLoading = true;

      await this.loadThing();

      this.isLoading = false;
    },
  },

  data() {
    return {
      thing: Thing.empty(),

      isLoading: false,
      isSiteParametersOpen: false,
    };
  },

  async created(): Promise<void> {
    this.isLoading = true;

    await this.loadThing();

    this.isLoading = false;
  },

  methods: {
    async loadThing(): Promise<void> {
      const thing = await ThingsService.getThing(this.thingId);
      this.thing = new Thing(thing);
    },
  },
});
