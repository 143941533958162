












import { debounce } from 'lodash-es';
import Vue, { PropOptions } from 'vue';
import { Thing } from '../../models/Thing';
import { ThingsService } from '../../services/things/ThingsService';
import { isEmpty, isString } from '../../utils/Utils';

const SEARCH_DEBOUNCE_IN_MS = 200;

export default Vue.extend({
  name: 'SitesSearch',

  props: {
    things: {
      type: Array,
      required: true,
    } as PropOptions<Thing[]>,
  },

  watch: {
    searchTerm: {
      immediate: true,
      handler(): void {
        this.debouncedUpdateThingsOnSearch();
      },
    },
    'things.length'() {
      this.updateThingsOnSearch();
    },
  },

  data() {
    return {
      searchTerm: '',
    };
  },

  created() {
    this.loadSearchTermQuery();
  },

  computed: {
    debouncedUpdateThingsOnSearch(): () => void {
      return debounce(() => {
        this.updateThingsOnSearch();
      }, SEARCH_DEBOUNCE_IN_MS);
    },
  },

  methods: {
    onClickClearSearchTerm(): void {
      this.searchTerm = '';
      this.updateThingsOnSearch();
    },
    updateThingsOnSearch(): void {
      const thingsResult = ThingsService.searchThings(
        this.things,
        this.searchTerm
      );
      this.$emit('search', [...thingsResult]);
      this.setSearchTermQuery();
    },
    loadSearchTermQuery(): void {
      const QUERY_KEY = 'q';

      const querySearchTerm = this.$route.query[QUERY_KEY];
      if (isString(querySearchTerm) && !isEmpty(querySearchTerm)) {
        this.searchTerm = querySearchTerm;
      }
    },
    async setSearchTermQuery(): Promise<void> {
      const QUERY_KEY = 'q';

      await this.$router
        .replace({
          query: {
            ...this.$route.query,
            [QUERY_KEY]: this.searchTerm,
          },
        })
        ?.catch(() => {
          // Silently ignore error.
        });
    },
  },
});
