




































import Vue, { PropOptions } from 'vue';
import SPagination from '../../components/common/SPagination.vue';
import { Thing } from '../../models/Thing';
import { isNumber } from '../../utils/Utils';
import SitesListItem from './SitesListItem.vue';

export default Vue.extend({
  name: 'SitesList',

  components: {
    SitesListItem,
    SPagination,
  },

  props: {
    things: {
      type: Array,
      required: true,
    } as PropOptions<Thing[]>,
  },

  async created(): Promise<void> {
    this.loadCurrentPageQuery();
  },

  computed: {
    isEmpty(): boolean {
      return this.things.length === 0;
    },
  },

  data() {
    return {
      currentPage: 1,
      pageSize: 10,

      paginatedThings: new Array<Thing>(),
    };
  },

  methods: {
    onPaginate(items: Thing[] = []) {
      this.paginatedThings = [...items];
    },
    loadCurrentPageQuery(): void {
      const queryCurrentPage = this.$route.query['current-page'];
      if (isNumber(queryCurrentPage)) {
        this.currentPage = Number(queryCurrentPage);
      }
    },
  },
});
