



























































import { debounce } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'SPagination',

  props: {
    /**
     * Use with `:current-page.sync="currentPage"`
     *
     * The current page will by persisted in $route.query.current-page.
     * It can easily be loaded with the following snippet:
     *
     * ```typescript
     * syncCurrentPageWithQuery(): void {
     *   const queryCurrentPage = this.$route.query['current-page'];
     *   if (isNumber(queryCurrentPage)) {
     *     this.currentPage = Number(queryCurrentPage);
     *   }
     * }
     * ```
     */
    currentPage: {
      default: 1,
      required: true,
      type: Number,
    },
    pageSize: {
      default: 12,
      required: false,
      type: Number,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  watch: {
    'items.length': {
      immediate: true,
      handler(): void {
        this.emitPaginate();
      },
    },
    currentPage: {
      immediate: true,
      handler(): void {
        this.syncCurrentPageWithQuery();
        this.debouncedEmitPaginate();
      },
    },
  },

  computed: {
    debouncedEmitPaginate(): () => void {
      return debounce(this.emitPaginate, 500, { trailing: true });
    },
    numberOfPages(): number {
      return Math.ceil(this.items.length / this.pageSize);
    },
    isPreviousDisabled(): boolean {
      return this.currentPage <= 1;
    },
    isMinusFiveDisabled(): boolean {
      return this.currentPage - 5 < 1;
    },
    isPlusFiveDisabled(): boolean {
      return this.currentPage + 5 > this.numberOfPages;
    },
    isNextDisabled(): boolean {
      return this.currentPage >= this.numberOfPages;
    },
  },

  methods: {
    async setCurrentPage(page: number): Promise<void> {
      await this.$emit('update:currentPage', page);
    },
    onClickMinusFive(): void {
      if (!this.isMinusFiveDisabled) {
        this.setCurrentPage(this.currentPage - 5);
      }
    },
    onClickPrevious(): void {
      if (!this.isPreviousDisabled) {
        this.setCurrentPage(this.currentPage - 1);
      }
    },
    onClickNext(): void {
      if (!this.isNextDisabled) {
        this.setCurrentPage(this.currentPage + 1);
      }
    },
    onClickPlusFive(): void {
      if (!this.isPlusFiveDisabled) {
        this.setCurrentPage(this.currentPage + 5);
      }
    },
    emitPaginate(): void {
      const startIndex = this.pageSize * (this.currentPage - 1);
      const endIndex = startIndex + this.pageSize;
      const paginatedItems = this.items.slice(startIndex, endIndex);

      this.$emit('paginate', paginatedItems);
    },
    async syncCurrentPageWithQuery(): Promise<void> {
      const QUERY_KEY = 'current-page';
      const currentPage = String(this.currentPage);
      await this.$router
        .replace({
          query: {
            ...this.$route.query,
            [QUERY_KEY]: currentPage,
          },
        })
        ?.catch(() => {
          // Silently ignore error.
        });
    },
  },
});
