
































import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { ThingsService } from '../../../services/things/ThingsService';
import SiteInfoDialog from './SiteInfoDialog.vue';

export default Vue.extend({
  name: 'SiteInfoDialogAction',

  props: {
    thingId: {
      type: String,
      required: true,
    },
  },

  watch: {
    async thingId(): Promise<void> {
      this.isLoading = true;

      await this.loadThing();

      this.isLoading = false;
    },
  },

  data() {
    return {
      isLoading: false,
      thing: Thing.empty(),
    };
  },

  components: {
    SiteInfoDialog,
  },

  async created(): Promise<void> {
    this.isLoading = true;

    await this.loadThing();

    this.isLoading = false;
  },

  methods: {
    async loadThing(): Promise<void> {
      const thing = await ThingsService.getThing(this.thingId);
      this.thing = thing;
    },
  },

  computed: {
    iconType(): string {
      if (this.thing.isPaused) {
        return 'mdi-pause';
      }

      if (this.thing.isOffline) {
        return 'mdi-cloud-off-outline';
      }

      if (this.thing.hasAlarms && !this.thing.isOffline) {
        return 'mdi-bell';
      }

      if (this.thing.isInactive) {
        return 'mdi-sleep';
      }

      return 'mdi-check-bold';
    },
    iconColor(): string {
      if (this.thing.isPaused) return 'orange';
      if (this.thing.isOffline) return 'error';
      if (this.thing.hasAlarms && !this.thing.isOffline) return 'accent';
      if (this.thing.isInactive) return 'warning';

      return 'primary';
    },
    tooltipText(): string {
      if (this.thing.isPaused) {
        return this.$t(
          'component.siteInfoDialogAction.subscriptionPaused'
        ) as string;
      }

      if (this.thing.isOffline) {
        return this.$t(
          'component.siteInfoDialogAction.gatewayOffline'
        ) as string;
      }

      if (this.thing.hasAlarms && !this.thing.isOffline) {
        return this.$t('component.siteInfoDialogAction.alarms', {
          alarms: this.thing.numberOfAlarms,
          units: this.thing.numberOfInstalledUnits,
        }) as string;
      }

      if (this.thing.isInactive) {
        return this.$t('component.siteInfoDialogAction.inactive') as string;
      }

      return this.$t('component.siteInfoDialogAction.info', {
        name: this.thing.name,
      }) as string;
    },
  },
});
