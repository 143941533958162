














































import Vue from 'vue';
import SitesList from '../components/sites/SitesList.vue';
import SitesMap from '../components/sites/SitesMap.vue';
import SitesSearch from '../components/sites/SitesSearch.vue';
import { Thing } from '../models/Thing';
import { LoadingService } from '../services/loading/LoadingService';
import { ThingsService } from '../services/things/ThingsService';

export default Vue.extend({
  name: 'SitesPage',

  components: {
    SitesSearch,
    SitesList,
    SitesMap,
  },

  data() {
    return {
      allThings: new Array<Thing>(),
      searchThings: new Array<Thing>(),
      isMapOpen: false,
    };
  },

  async created(): Promise<void> {
    LoadingService.setIsLoading(true);

    await this.loadThings();

    LoadingService.setIsLoading(false);
  },

  computed: {
    isMapTogglable(): boolean {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isMapOpenText(): unknown {
      return this.isMapOpen
        ? this.$t('component.sites.hideMap')
        : this.$t('component.sites.showMap');
    },
  },

  methods: {
    onToggleIsMapOpen(): void {
      this.isMapOpen = !this.isMapOpen;
    },
    onSearchThings(things: Thing[]): void {
      this.searchThings = [...things];
    },
    async loadThings(): Promise<void> {
      const things = await ThingsService.getThings();

      this.allThings = [...things];
      this.searchThings = [...this.allThings];
    },
  },
});
