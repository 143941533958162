


































import Vue from 'vue';
import { Thing } from '../../models/Thing';
import SiteInfoDialogAction from './dialogs/SiteInfoDialogAction.vue';

export default Vue.extend({
  name: 'SitesListItem',

  components: {
    SiteInfoDialogAction,
  },

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },
});
