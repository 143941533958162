

































import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { ThingsService } from '../../../services/things/ThingsService';
import SiteAlerts from '../SiteAlerts.vue';
import SiteParameters from '../SiteParameters.vue';
import SiteSignalStrength from '../SiteSignalStrength.vue';

export default Vue.extend({
  name: 'SiteInfoDialog',

  components: {
    SiteAlerts,
    SiteParameters,
    SiteSignalStrength,
  },

  watch: {
    async thingId(): Promise<void> {
      await this.loadThing();
    },
  },

  props: {
    thingId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      thing: Thing.empty(),
      isDialogOpen: false,
    };
  },

  created(): void {
    this.loadThing();
  },

  methods: {
    async loadThing(): Promise<void> {
      const thing = await ThingsService.getThing(this.thingId);
      this.thing = new Thing(thing);
    },
    onClickOpen(): void {
      this.isDialogOpen = true;
    },
    onClickClose(): void {
      this.isDialogOpen = false;
    },
  },
});
