var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('site-info-dialog',{attrs:{"thing-id":_vm.thingId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var onClickOpen = ref.onClickOpen;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.iconColor},on:{"click":function($event){$event.preventDefault();return onClickOpen.apply(null, arguments)}}},'v-btn',attrs,false),on),[(_vm.isLoading)?_c('v-progress-circular',{attrs:{"size":24,"width":2,"indeterminate":"","color":"accent"}}):_c('v-tooltip',{attrs:{"bottom":"","color":"accent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',tooltipAttrs,false),tooltipOn),[_vm._v(_vm._s(_vm.iconType))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }