














import MarkerClusterer from '@googlemaps/markerclustererplus';
import Vue, { PropOptions } from 'vue';
import { Thing } from '../../models/Thing';
import { ConnectionStatus } from '../../services/things/models/ConnectionStatus';
import {
  createMarkerCluster,
  defaultZoom,
  getDefaultCoordinates,
} from '../../utils/GoogleMapsUtils';
import { isDefined } from '../../utils/Utils';
import SiteMarker from './SiteMarker.vue';

export default Vue.extend({
  name: 'SitesMap',

  components: {
    SiteMarker,
  },

  props: {
    things: {
      type: Array,
      required: true,
    } as PropOptions<Thing[]>,
  },

  watch: {
    'things.length'() {
      this.drawMarkers();
      this.centerFirstMarker();
    },
  },

  data() {
    return {
      map: undefined as google.maps.Map | undefined,
      markers: new Array<google.maps.Marker>(),
      markerCluster: undefined as MarkerClusterer | undefined,
      selectedThing: '',
      selectedMarker: undefined as google.maps.Marker | undefined,
      infoWindow: new google.maps.InfoWindow(),
    };
  },

  async mounted(): Promise<void> {
    await this.setupMap();
    this.drawMarkers();
  },
  methods: {
    async setupMap(): Promise<void> {
      const mapElement = this.$el.querySelector<HTMLElement>('[data-id="map"]');
      if (mapElement) {
        const coordinates = await getDefaultCoordinates();
        this.map = new google.maps.Map(mapElement, {
          center: { lat: coordinates.latitude, lng: coordinates.longitude },
          zoom: defaultZoom,
        });
      }
    },
    drawMarkers(): void {
      this.markerCluster?.clearMarkers();
      this.markers.forEach(marker => marker.setMap(null));

      const markers = this.things.map(this.createMarker).filter(isDefined);
      this.markers = [...markers];
      if (this.map) {
        this.markerCluster = createMarkerCluster(this.map, this.markers);
      }
    },
    centerFirstMarker(): void {
      const [marker] = this.markers;
      const position = marker?.getPosition();
      if (position) this.map?.panTo(position);
    },
    createMarker(thing: Thing): google.maps.Marker | undefined {
      if (thing.coordinates) {
        let mapMarkerImageName = 'map-marker-grey.png';

        if (thing.connectionStatus === ConnectionStatus.Offline) {
          mapMarkerImageName = 'map-marker-red.png';
        }

        const marker = new google.maps.Marker({
          position: {
            lat: thing.coordinates.latitude,
            lng: thing.coordinates.longitude,
          },
          title: thing.thingName,
          icon: {
            url: `/img/maps/${mapMarkerImageName}`,
            scaledSize: new google.maps.Size(48, 48),
          },
          map: this.map,
        });
        marker.addListener('click', async () => {
          this.selectedMarker = marker;
          this.selectedThing = this.selectedMarker.getTitle();

          await this.$nextTick();
          if (this.$refs.selectedMarker && '$el' in this.$refs.selectedMarker) {
            this.infoWindow.setContent(this.$refs.selectedMarker.$el);
            this.infoWindow.open(this.map, this.selectedMarker);
          }
        });

        return marker;
      }
      return undefined;
    },
  },
});
